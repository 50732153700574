import React from "react";
import "./HeadingPage.scss";
import logo from "../../assets/icons/devine-logo.png";

const HeadingPage: React.FC<{ label: string }> = ({ label }) => {
  return (
    <>
      <div className="heading-page flex flex-col">
        <img src={logo} alt="Divine Logo" className="heading-page--logo mx-auto" />
        <div className="heading-page--text-wrap">
          <h2 className="heading-page--text">{label}</h2>
        </div>
      </div>
      <hr />
    </>
  );
};

export default HeadingPage;
