import React, { useEffect, useRef } from 'react';

interface TikTokEmbedProps {
  url: string;
  videoId: string;
}

const TikTokEmbed: React.FC<TikTokEmbedProps> = ({ url, videoId }) => {
  const tiktokRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://www.tiktok.com/embed.js');
    script.async = true;

    tiktokRef.current?.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      tiktokRef.current?.removeChild(script);
    };
  }, [videoId]); // Re-run the effect if the videoId changes

  const embedHtml = `
    <blockquote class="tiktok-embed" cite="${url}" data-autoplay="false" data-video-id="${videoId}" style="max-width: 605px; min-width: 325px; height: 578px;">
      <section>
        <a target="_blank" title="Go to TikTok video" href="${url}">
          Click here to watch the video
        </a>
      </section>
    </blockquote>
  `;

  return (
    <div ref={tiktokRef} dangerouslySetInnerHTML={{ __html: embedHtml }} />
  );
};

export default TikTokEmbed;
