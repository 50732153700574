export interface Video {
    url: string;
    videoId: string;
}

export const videos: Video[] = [
    { url: "https://www.tiktok.com/@user/video/7374205569936182536", videoId: "7374205569936182536" },
    {url:"https://www.tiktok.com/@divinenailtimaru/video/7378028896718998791",videoId:"7378028896718998791"},
    {url:"https://www.tiktok.com/@divinenailtimaru/video/7378391078518426887",videoId:"7378391078518426887"},
    // {url:"https://www.tiktok.com/@divinenailtimaru/video/7379898980555148562",videoId:"7379898980555148562"},
];