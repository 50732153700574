import React, { useEffect, useState } from "react";
import "./Dropdown.scss";
import { useNavigate } from "react-router-dom";

type DropdownProps = { label: string; path: string; currentPage: string };

const Dropdown: React.FC<DropdownProps> = ({ label, path, currentPage }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(path);
  };
  const [inCurrentPage, setInCurrentPage] = useState(false);
  useEffect(() => {
    if (currentPage == label) {
      setInCurrentPage(true);
    }
  }, [currentPage, label]);

  const buttonClassName = `dropdown--btn${inCurrentPage ? " currentPage" : ""}`;

  return (
    <div className="dropdown">
      <button className={buttonClassName} onClick={handleClick}>
        {label}
      </button>
    </div>
  );
};

export default Dropdown;
