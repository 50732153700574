import React, { useEffect, useState } from "react";
import TikTokEmbed from "../../components/TikTokEmbed/TikTokEmbed";
import "./VideoGallery.scss";
import HeadingPage from "../../components/HeadingPage/HeadingPage";
import GoogleTagManager from "../../components/GoogleTagManager/GoogleTagManager";
import Navbar from "../../components/Navbar/Navbar";
import { videos } from "../../utils/videos";

const VideoGallery: React.FC = () => {
  return (
    <div className="video-gallery-wrap pt-32">
      <GoogleTagManager trackingId="G-L4Z0PNNQMK" />
      <Navbar isStickyProp={true} currentPage="Video" />
      <div className="video-gallery-content">
        <HeadingPage label="Video"></HeadingPage>
        <div className="video-gallery">
        {videos.map(video => (
            <TikTokEmbed key={video.videoId} url={video.url} videoId={video.videoId} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
