import "./Navbar.scss";
import React, { useState, useEffect } from "react";
import Dropdown from "../Dropdown/Dropdown";
import logo from "../../assets/icons/devine-logo.png";
import { FaBars, FaTimes } from "react-icons/fa"; 
type NavbarProps = {
  isStickyProp: boolean;
  currentPage: string;
};

const Navbar: React.FC<NavbarProps> = ({ isStickyProp, currentPage }) => {
  const [isSticky, setIsSticky] = useState(isStickyProp);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleScroll = () => {
    if (isStickyProp) return;
    const offset = window.scrollY;
    setIsSticky(offset > 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`navbar ${isSticky ? "navbar-sticky" : ""} `}>
      <div className="navbar--wrap">
        <a href="/">
          <img src={logo} alt="" className="w-16 h-16 rounded-full" />
        </a>
        <button className="navbar-mobile-toggle" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
        <div className={`navbar-menu ${isMobileMenuOpen ? "active" : ""}`}>
          <Dropdown path="/" label="Home" currentPage={currentPage}/>
          <Dropdown path="/about-us" label="About Us" currentPage={currentPage}/>
          <Dropdown path="/service" label="Service" currentPage={currentPage}/>
          <Dropdown path="/booking" label="Booking" currentPage={currentPage}/>
          <Dropdown path="/gallery" label="Gallery" currentPage={currentPage}/>
          <Dropdown path="/video" label="Video" currentPage={currentPage}/>
          <Dropdown path="/Policy" label="Policy" currentPage={currentPage}/>
          <Dropdown path="/contact-us" label="Contact Us" currentPage={currentPage}/>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
